	var mapboxgl = require('mapbox-gl');
	var $ = require("jquery");

	if ( $( "#map" ).length ) {
		const axios = require('axios');
	  var centLong, centLat, zoom;

	  if(directionStart) {
	    centLong = searchLong;
	    centLat = searchLat;
	    zoom = 14;
	  } else {
	    centLong = -1.070085;
	    centLat = 53.381130;
	    zoom = 5;
	  }

		mapboxgl.accessToken = 'pk.eyJ1IjoiODFwbGF0Zm9ybSIsImEiOiJjbGhwZngxeDMxemMyM2ZveDdyNnhkZG9nIn0.JQcIqGDjxo9gAjPfKL7oWA';
		var map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/81platform/clhpg36nu01uh01qyef7jabes',
			center: [centLong, centLat],
			zoom: zoom,
			scrollZoom: true,
		});
		map.scrollZoom.disable();
		map.addControl(new mapboxgl.NavigationControl());


		locationsObject.features.forEach(function(location, i){
	    i = i+1;
			location.properties.position = i;
		});

		map.on('style.load', function(){
			map.addSource('locations', {
				type: "geojson",
				data: locationsObject,
			});

			addMarkers();
		});

		/**
		* Add a marker to the map for every store listing.
		**/
		function addMarkers() {
			/* For each feature in the GeoJSON object above: */
			locationsObject.features.forEach(function(marker) {
			  /* Create a div element for the marker. */
			  var el = document.createElement('div');
			  /* Assign a unique `id` to the marker. */
			  el.id = "marker-" + marker.properties.position;
			  // el.textContent = marker.properties.position;
			  /* Assign the `marker` class to each marker for styling. */
			  el.className = 'marker';

			  if(marker.properties.type) {
			    el.classList.add('marker--'+marker.properties.type)
			  }

			  if(marker.properties.position ==1) {
			    el.classList.add('marker--first');
			  }
			  /**
			   * Create a marker using the div element
			   * defined above and add it to the map.
			  **/
			  new mapboxgl.Marker(el, { offset: [0, -23] })
			    .setLngLat(marker.geometry.coordinates)
			    .addTo(map);

			  /**
			   * Listen to the element and when it is clicked, do three things:
			   * 1. Fly to the point
			   * 2. Close all other popups and display popup for clicked store
			   * 3. Highlight listing in sidebar (and remove highlight for all other listings)
			  **/
			  el.addEventListener('click', function(e){
			    /* Fly to the point */
			    flyToStore(marker);
			    /* Close all other popups and display popup for clicked store */
			    createPopUp(marker);
			    /* Highlight listing in sidebar */
			    var markers = document.getElementsByClassName("marker");
				Array.from(markers).forEach(function(item, index){
				  item.classList.remove("marker--active")
				})
			    el.classList.add('marker--active');

	            /* Highlight listing in sidebar */
	            var activeItem = document.getElementsByClassName('result--active');
	            if (activeItem[0]) {
	              activeItem[0].classList.remove('result--active');
	            }

	            var listing = document.getElementById(
	              'result-' + marker.properties.position
	            );
	            listing.classList.add('result--active');
			  });
			});
		}

		var results = document.getElementsByClassName("find-location__select")[0];
		results.addEventListener("change", function() {
			var options = results.options;
			var id = options[options.selectedIndex].id;

	        for (var i = 0; i < locationsObject.features.length; i++) {
	          if (id === 'result-' + locationsObject.features[i].properties.position) {
	            var clickedListing = locationsObject.features[i];
	            flyToStore(clickedListing);
	            createPopUp(clickedListing);
	          }
	        }
		});

	      /**
	       * Use Mapbox GL JS's `flyTo` to move the camera smoothly
	       * a given center point.
	      **/
	      function flyToStore(currentFeature) {
	        map.flyTo({
	          center: currentFeature.geometry.coordinates,
	          zoom: 6
	        });
	      }

		/**
		* Create a Mapbox GL JS `Popup`.
		**/
		function createPopUp(currentFeature) {
			var popUps = document.getElementsByClassName('mapboxgl-popup');
			var typeClass = "mapboxgl-popup";

			if (popUps[0]) popUps[0].remove();

			var popup = new mapboxgl.Popup({closeOnClick: false, className: typeClass})
			  .setLngLat(currentFeature.geometry.coordinates)
				.setHTML(
				'<div class="mapboxgl-popup-top"><p class="title title--address">'+currentFeature.properties.title+'</p> <address>'+currentFeature.properties.address+'</address></div>' +
				'<div class="mapboxgl-popup-bottom">' +
				'<a href="'+currentFeature.properties.location_url+'" class="ui-btn ui-btn--b">View Courses</a>' +
				'<a href="'+currentFeature.properties.directions_url+'" class="ui-link">Get Directions</a>' +
				'</div>'
				)
				.setMaxWidth("400px")
			  	.addTo(map);
		
		}
	}
